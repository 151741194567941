import React from 'react'
import ContactSmallEmail from '../components/ContactSmallEmail';
import { Card } from 'antd';
import location from '../assets/location2.png'
import { Widget } from '@typeform/embed-react'


function Contact() {
    return (
        <div className='ContactPage'>
            <div className='con-container'>
                <div className='overlay'></div>
                <div className='hero'></div>
                <div className='infobox'>
                    <Card title="Contact Information" className='con-card'>
                        <p><a href='mailto:rene@daiglegroup.com'>Email: rene@daiglegroup.com</a></p>
                        <p><a href='tel:9853021106'>Mobile: (985) 302-1106</a></p>
                    </Card>
                    <Card title='Location' className='con-card'><img src={location} alt='location' style={{width: "325px", height: "250px", marginBottom: "10px"}}></img>
                    <p style={{fontSize: "14px"}}>Daigle Sales Group LLC <br></br>
                    312 S. Jefferson Ave.  Ste. C <br></br>
                    Covington, LA 70433</p>
                    </Card>
                </div>
                {/* <ContactSmallEmail className='contact-form'/> */}
                <Widget id="GRBb9MMH" style={{ width: '100%' }} className="my-form" />
            </div>
        </div>
    )
}

export default Contact
