import React from 'react';
import {Link} from "react-router-dom";

function Footer() {




    return (

        <div className="footer">


            <div className="ft-container">
                <div className="logo"></div>

                <div className="logo-triangle-footer"></div>

                <div className='description-container'>
                    <div className="description-box">
                        <p>The information presented was assembled with care and is believed to be current and accurate on the date presented; however, the information was prepared by the manufacturers represented and not by DSG LLC, and/or Rene Daigle, 
                            who cannot and does not extend any warranty either express or implied for the company or himself or on behalf of the manufacturer, all of which are disclaimed, including without limitation, 
                            the implied warranty of fitness for a particular purpose. Services by DSG LLC, and/or Rene Daigle are not intended or offered in substitution for the design professional’s judgment and due diligence. 
                            It is expected that a design professional will exercise professional judgment and due diligence before issuing Contract Documents for a specific application under a professional seal and signature in accordance with applicable laws.
                        <br></br>
                        <br></br>
        
                        
                        </p>
                    </div>
                </div>

                <div className='info-container'>
                    <div className="info-box">

                        <div className="contact-info">
                        <h5>Contact</h5>
                            <p>
                                <br></br> Email: rene@daiglegroup.com 
                                <br></br>Phone: (985) 303-1107
                                <br></br>Fax: (225) 208-1820
                                </p>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default Footer