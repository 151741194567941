import React from 'react';
import ContactSmall from '../components/ContactSmall';
import Footer from '../components/Footer';
import BP1 from '../assets/blueprint-1.png';
import {Link} from 'react-router-dom';
import PartnerSection from '../components/PartnerSection';



function Home() {
    return (
        <div className="HomePage">
            <div className='Home-grid'>
                <div className='overlay'></div>
                <div className='hero'>
                    <div className='hero-info'>
                        <h2>Daigle Sales Group</h2>
                        <p>Daigle Sales Group, located in Covington Louisiana is a sales representative firm for building materials
                            manufacturers. Our mission is to use our many years of knowledge and experience in the commercial roofing industry to 
                            provide all of our clients with the best possible solutions for their particular
                            project.</p>
                        <Link className="hero-button contact" to="/Contact"><span>Contact</span></Link>
                    </div>
                </div>
                <div className='content-wrapper'>
                    <div className='content-section'>
                        <img src={BP1} alt='buildings' />
                        <h2>Sales and Technical Datas</h2>
                        <p>Finding out what you need on a manufacturer's website
                        can be a daunting task. I've made that easier for you.
                        Here are some of the most common links used.
                        <br></br>
                        For more information, please refer to our Resources page.</p>
                        <Link className="learn-button resources" to="/Resources"><span>Resources</span></Link>
                        <ul>
                            <li className='list-links'><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                <a href="https://www.gaf.com/en-us/document-library?Action=SelectCategory&ActionData=eyJDYXRlZ29yeUlkIjoiUmVzaWRlbnRpYWwiLCJTZWN0aW9uSXRlbUlkIjoiUkdGMFlTQlRhR1ZsZEhNIn0_&Filters_110=Everything">GAF</a></li>
                            <li className='list-links'><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                <a href="https://ludowici.com/resources/technical-documentation/">Ludowici</a></li>
                            <li className='list-links'><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                <a href="https://buildgp.com/gypsum/exterior-roof/">Georgia Pacific</a></li>
                            <li className='list-links'><i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                <a href="https://www.metalera.com/resources">Metal-Era</a></li>
                        </ul>

                    </div>
                </div>
                <div className='image-holder'>
                    <figure className='image one'><img></img></figure>
                    <figure className='image two'><img></img></figure>
                    <figure className='image three'><img></img></figure>
                    <figure className='image four'><img></img></figure>
                </div>
                <div className='contact-small'>
                    <PartnerSection />
                </div>
            </div>
        </div>
    )
}

export default Home
