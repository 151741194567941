import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import Nav from './components/Nav';
import Home from './pages/Home';
import Resources from './pages/Resources';
import Partners from './pages/Partners';
import About from './pages/About';
import Contact from './pages/Contact';
import './sass/main.css';
import Footer from './components/Footer';
import PartnerSection from './components/PartnerSection';
import ProjectAssistance from './pages/ProjectAssistance';

function App() {
  return (
    <Router>
    <div className="App">
      <Nav />
        <Route exact path='/' component={Home}></Route>
        <Route path='/resources' component={Resources}></Route>
        {/* <Route path='/projectassistance' component={ProjectAssistance}></Route> */}
        <Route path='/about' component={About}></Route>
        <Route path='/contact' component={Contact}></Route>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
