import React from 'react'
import Firestone from '../assets/Firestone.png';
import Elevate from '../assets/Elevate_logo-1.png';
import Genflex from '../assets/GenFlex.png';
import MetalEra from '../assets/metalEra.png';
import Ludo from '../assets/Ludowici.png'
import GAF from '../assets/GAF.png'
import DensDeck from '../assets/DensDeck.png';
import GP from '../assets/GP.png';
import Gaco from '../assets/Gaco.png'
import {Link} from 'react-router-dom';




function Resources() {
    return (
        <div className="ResourcesPage">
            <div className='overlay'></div>
            <div className='resrc-hero'>
                <div className='resrc-hero-info'>
                    <h2>Quick Links</h2>
                    <p>Below is a list of my partners and links to the most commonly needed areas of their websites. </p>
                </div>
            </div>

            <div className='bgGrid'>

                <div className='gridBox one'>
                    <figure className='image1'></figure>
                </div>

                <div className='gridBox two'></div>
                <div className='gridBox three'></div>
                <div className='gridBox four'></div>
                <div className='gridBox five'></div>

                <div className='gridBox six'>
                    <figure className='image2'></figure>
                </div>
            </div>


            <div className='card-section'>

                <div className="card one">

                    <div className="card-image">
                        <figure className="image">
                            <img src={Ludo} alt="Ludowici" />
                        </figure>
                    </div>

                    <div className="card-content">

                        <div className="content">
                            <h3>Links</h3> <br></br>
                            <div className='card-links'>
                                <a href="https://ludowici.com/resources/technical-documentation/">Technical Data sheets</a>
                                <a href="https://ludowici.com/resources/literature/">Guides</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card two">

                    <div className="card-image">
                        <figure className="image">
                            <img src={GAF} alt="GAF" />
                        </figure>
                    </div>

                    <div className="card-content">

                        <div className="content">
                            <h3>Links</h3><br></br>

                            <div className='card-links'>
                                <a href="https://www.gaf.com/en-us/document-library?Action=SelectCategory&ActionData=eyJDYXRlZ29yeUlkIjoiUmVzaWRlbnRpYWwiLCJTZWN0aW9uSXRlbUlkIjoiUkdGMFlTQlRhR1ZsZEhNIn0_&Filters_110=Everything">Technical Data sheets</a> <br></br>
                                <a href="https://www.gaf.com/en-us/document-library?Action=GetTree&Filters_110=Everything">All resources</a> <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card three">

                    <div className="card-image">
                        <figure className="image">
                            <img src={MetalEra} alt="Metal Era" />
                        </figure>
                    </div>

                    <div className="card-content">


                        <div className="content">
                            <h3>Links</h3> <br></br>
                            <div className='card-links'>
                                <a href="https://www.metalera.com/brands/firestone">Firestone Forms</a>
                                <a href="https://www.metalera.com/Brands/GenFlex">GenFlex Forms</a> <br></br>
                                <a href="https://www.metalera.com/resources/download-library">Download Library</a> <br></br>
                                <a href="https://www.metalera.com/resources/calculators">Calculator</a>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="card four">

                    <div className="card-image">
                        <figure className="image">
                            <img src={Gaco} alt="Gaco" />
                        </figure>
                    </div>

                    <div className="card-content">

                        <div className="content">
                            <h3>Links</h3>

                            <div className='card-links'>
                                <a href="https://gaco.com/products/">Product Data sheets</a> <br></br>
                                <a href="https://www.youtube.com/user/GacoWestern/videos">Training Videos</a> <br></br>
                                <a href="https://gaco.com/warranty/">Warranty</a>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="card five">

                    <div className="card-image">
                        <figure className="image">
                            <img src={GP} alt="Georgia Pacific" />
                        </figure>
                    </div>

                    <div className="card-content">


                        <div className="content">
                            <h3>Links</h3>
                            <div className='card-links'>
                                <a href="https://buildgp.com/densdeck/">Technical Data sheets</a> <br></br>
                                <a href="https://buildgp.com/wp-content/uploads/2019/01/DensDeck_Prime_Technical_Guide.pdf">Technical Guide</a>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>




            </div>


            {/* <div className='place-holder'></div>
            <div className='image-holder'></div>
            <div className='place-holder2'></div> */}
        </div>
    )
}

export default Resources
