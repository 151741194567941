import React from 'react'
import Firestone from '../assets/Firestone.png';
import Elevate from '../assets/Elevate_logo-1.png'
import GAF from '../assets/GAF.png'
import Ludo from '../assets/Ludowici.png'
import Genflex from '../assets/GenFlex.png';
import MetalEra from '../assets/metalEra.png';
import GP from '../assets/GP.png';
import Gaco from '../assets/Gaco.png';
// import styled, { keyframes } from 'styled-components';
// import {pulse} from 'react-animations';


function PartnerSection() {


    // const Pulse = styled.div`animation: 1s ${keyframes`${pulse}`} infinite`;


    return (
        <div className='PartnerLayout'>
            <div className='sidebar'>
                <h1>Our Partners</h1>
            </div>
            <section className='Partnermain'>

                <div className='partnerBox box1'>
                    <figure className='partnerlogo'>
                        <a href="https://www.gaf.com/en-us"><img src={GAF} className='partnerImg' style={{height: '100%', width: '60%'}} /></a>
                    </figure>
                </div>

                <div className='partnerBox box2'>
                    <figure className='partnerlogo'>
                        <a href="https://ludowici.com/resources/technical-documentation/"><img src={Ludo} className='partnerImg' style={{height: '16vh', width: '24vw'}} /></a>
                    </figure>
                </div>
                <div className='partnerBox box3'>
                    <figure className='partnerlogo'>
                        <a href="https://www.metalera.com/"><img src={MetalEra} className='partnerImg' style={{height: '12vh', width: '28vw'}} /></a>
                    </figure>
                </div>
                <div className='partnerBox box4'>
                    <figure className='partnerlogo'>
                        <a href="https://www.gp.com/"><img src={GP} className='partnerImg' style={{height: '17vh', width: '22vw'}} /></a>
                    </figure>
                </div>

            </section>
        </div>
    )
}

export default PartnerSection
