import React from 'react';
import { NavLink, NavNavLink } from "react-router-dom";

function Nav() {

    return (

        <nav className="navbar">
            <div className="logo-triangle"></div>
            
            <div className="btn-group">
                <ul>
                <NavLink className="navbar button home" exact to="/" activeClassName='active-page'>Home</NavLink>
                <NavLink className="navbar button resources" to="/resources" activeClassName='active-page' >Resources</NavLink>
                {/* <NavLink className="navbar button assistance" to="/projectassistance" activeClassName='active-page' >Project Assistance</NavLink> */}
                {/* <NavLink className="navbar button partners" to="/partners" activeClassName='active-page'>Partners</NavLink> */}
                <NavLink className="navbar button about" to="/about" activeClassName='active-page'>About</NavLink>
                <NavLink className="navbar button contact" to="/contact" activeClassName='active-page'>Contact</NavLink>
                </ul>
            </div>
            <div className="logo"></div>
        </nav>
    )
}

export default Nav
