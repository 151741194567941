import React from 'react'
import {Typography} from 'antd';
import DriCon from '../assets/Dri-Con pic 2 (003).jpg'

function About() {

    const {Title}=Typography;

    return (
        <div className="AboutPage">
            <div className='overlay'></div>
            <div className='hero'></div>
            <div className='content-section'>
                <div className='sidebar'>
                    <Title level={2} className='title'>About</Title>
                </div>
                <div className='about'> Rene Daigle began his career in the commercial roofing industry in the 1990s, first as
                the commercial territory manager for GS Roofing Products before moving into Roofing Materials
                distribution. Rene spent many years in the distribution business gaining a unique understanding of the
                complex nature of the roofing business throughout the deep south from his dealings with the many
                different contractors he has developed relationships with. In 2015 the decision was made to break away
                from the distribution side of the business and start Daigle Sales Group LLC. offering his years of
                experiences to many contractors, building owners, design professionals and contractors he works with.
                <br></br>
                Rene and his wife, Danielle have been married since 1984 and have 3 boys, Rene Jr. (1988),
                Jordan (1992) and Gabriel(2001).</div>
            </div>
        </div>
    )
}

export default About
