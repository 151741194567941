import React from 'react';
import emailjs from 'emailjs-com'
import {message} from 'antd';

function ContactSmallEmail() {



    const loadingMessage=() =>
        message
            .loading('Checking your fields..',2)

    const incompleteMessage=() => {
        message
            .info('Fields incomplete. All fields are required',3)
    }

    const successMessage=() =>
        message
            .success('Message sent. Thank you for contacting us.',2);



    const handleSubmit=(e) => {
        e.preventDefault();
        const name=e.target.name.value;
        const phone=e.target.phone.value;
        const email=e.target.email.value;
        const usermessage=e.target.usermessage.value;


        if((name===""||email===""||phone===""||usermessage==="")) {
            incompleteMessage();
            return;
        } else {
            
            console.log(name)
            console.log(email)
            console.log(phone)
            console.log(usermessage);

            emailjs.sendForm("service_6xr3b7q","template_3kly32n",e.target,'user_EiIMHmFkliFaFqw3Aol9j')
                .then((result) => {
                    console.log(result.text);
                },(error) => {
                    console.log(error.text);
                });
                
            e.target.reset()
            successMessage();
        }
    }





    return (
        <div className='ContactSmall'>
            <div className="container-contact100">
                <div className="wrap-contact100">
                    <form className="contact100-form validate-form" onSubmit={handleSubmit}>
                        <span className="contact100-form-title">
                            Get in Touch
				        </span>

                        <div className="wrap-input100 validate-input" data-validate="Name is required">
                            <input className="input100" id="name" type="text" name="name" placeholder="Name" />
                        </div>

                        <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input className="input100" id="email" type="text" name="email" placeholder="Email" />
                        </div>

                        <div className="wrap-input100 validate-input" data-validate="Phone is required">
                            <input className="input100" id="phone" type="text" name="phone" placeholder="Phone" />
                        </div>

                        <div className="wrap-input100 validate-input" data-validate="Message is required">
                            <textarea className="input100" name="usermessage" placeholder="Your message..."></textarea>
                        </div>

                        <div className="container-contact100-form-btn">
                            <div className="wrap-contact100-form-btn">
                                <div className="contact100-form-bgbtn"></div>
                                <button className="contact100-form-btn">
                                    Send Email
						        </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default ContactSmallEmail;