import React from 'react'

function Partners() {
    return (
    <div className="PartnersPage">
        <div className='hero'></div>
        <p>This is the Partners page</p>
    </div>
    )
}

export default Partners
